body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
}

::-webkit-datetime-edit-day-field:focus {
  background-color: rgba(140,12,240,0.4);
  color: rgba(0,0,0,2);
  outline: none;
  border-radius: 5px;
}

::-webkit-datetime-edit-month-field:focus {
  background-color: rgba(140,12,240,0.4);
  color: rgba(0,0,0,2);
  outline: none;
  border-radius: 5px;
}

::-webkit-datetime-edit-year-field:focus {
  background-color: rgba(140,12,240,0.4);
  color: rgba(0,0,0,2);
  outline: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track:horizontal {
  background-color: transparent;
}

/* Specifically for horizontal scrollbar thumb: */
::-webkit-scrollbar-thumb:horizontal {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
  border-radius: 20px;
}
